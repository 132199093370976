import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../css/main.css'
import Hero from '../Hero';


const Milestones3 = () => {

    const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
    return(
        <div>
            <Hero />

            <h1 className="thematic-area-title">Milestones</h1>
    
            <div className="milestones-body">
                <div className="progress-keys">
                    <div className="completed-tag">Completed</div>
                    <div className="ongoing-tag">Ongoing</div>
                    <div className="not-started-tag">Not started</div>
                </div>
            <br></br>
            

{/* commitment 5 */}
<h2 className="commitment-heading">Commitment 5: Gender Equity</h2>

<table className="table">
    <tr>
        <th>Description of Milestones</th>
        <th className="progress-col">Status</th>
    </tr>
    <tr>
      <td>Advocacy visits to key stakeholders</td>
      <td className="completed-item"></td>
    </tr>
    <tr>
      <td>Gender and Equal Opportunities Law reviewed and passed </td>
      <td className="ongoing-item"></td>
    
    </tr>
    <tr>
        <td>Take-off funds for the Commission mobilized</td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Board of the Commission constituted</td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Key management staff appointed</td>
        <td className="not-started-item"></td>
    </tr>
    <tr>
        <td>Beneficiary stakeholders sensitized <div className="fill"></div></td>
        <td className="ongoing-item"></td>
    </tr>

  </table>


{/* commitment 6 */}
  <h2 className="commitment-heading">Commitment 6: Peace and Security</h2>

  <table className="table">
    <tr>
        <th>Description of Milestones</th>
        <th>Status</th>
      </tr>
      <tr>
        <td>Development of the Concept Note </td>
        <td className="completed-item"></td>
      </tr>
      <tr>
        <td>Development of the Action Plan</td>
        <td className="ongoing-item"></td>
      </tr>
      
      <tr>
          <td>Peace and Security Trust Fund Law enacted</td>
          <td className="ongoing-item"></td>
      </tr>
      <tr>
          <td>Peace and Security Fund mobilized</td>
          <td className="ongoing-item"></td>
      </tr>
      <tr>
          <td>Board of Trustees inaugurated </td>
          <td className="not-started-item"></td>
      </tr>
      <tr>
            <td>Management and Staff appointed</td>
            <td className="not-started-item"></td>
      </tr>

      <tr>
            <td>Office Structure Commissioned</td>
            <td className="not-started-item"></td>
      </tr>
      

  </table>
  </div>
        <br></br>

        <div className="pagination">
           <Link to='/milestones'>1</Link>
           <Link to='/milestones-2'>2</Link>
           <Link to='/milestones-3' className='active'>3</Link>
        
      </div>

        </div>
    )
}

export default Milestones3;
import '../css/main.css'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';


const ContactForm = () => {

    const [formData, setFormData] = useState({})
    const [message, setMessage] = useState(false)

    const handleInput = ({target}) => {
        setFormData({...formData, [target.name]:target.value})
    }

    useEffect(()=> {
        // console.log(formData)

        if (message){
            setTimeout(() => {
                setMessage(false)
            }, 3000);
        }
    })

    const handleSubmit = (event) =>{
        event.preventDefault()

        
        if (formData.subject){
            emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, event.target, process.env.REACT_APP_MAIL_USER_ID)

          .then((result) => {
              console.log(result.text);
              alert('message sent')
          }, (error) => {
              console.log(error.text);
          });
          event.target.reset()
        
        }else{
            setMessage(true)
        }

    }

        
    return (
    <div className='contact-form-container'>
        <form className='contact-form' onSubmit={handleSubmit}>

            <label htmlFor='firstname'>First Name:</label>
            <br></br>
            <input 
            type='text'
            id='firstname'
            name='firstname'
            placeholder='Enter your first name'
            onChange={handleInput}
            required
            />
            <br></br>

            <label htmlFor='surname'>Surname:</label>
            <br></br>
            <input 
            type='text'
            id='surname'
            name='surname'
            placeholder='Enter your surname'
            onChange={handleInput}
            required
            />
            <br></br>

            <label htmlFor='email'>Email:</label>
            <br></br>
            <input 
            type='email'
            name='email'
            placeholder='Enter your email address'
            onChange={handleInput}
            required
            />
            <br></br>

            <label htmlFor='commitments' >Please select your subject matter:</label>
            <br></br>
            {message? <small style={{color: 'red'}}>please select a subject</small> : null}
            <select name='subject' className='select' defaultValue='initial' onChange={handleInput} required>
                <option value='initial' disabled>--select--</option>
                <option>Open Contracting</option>
                <option>Participatory Budgeting</option>
                <option>Citizens Engagement</option>
                <option>Extractive Transparency</option>
                <option>Gender Equity</option>
                <option>Peace and Security</option>
                <option>Other</option>

            </select>


            <br></br>

            <label htmlFor='message'>Message:</label>
            <br></br>
            <textarea 
            id='message'
            name='message'
            cols={20}
            rows={10}
            placeholder='Enter your message here'
            onChange={handleInput}
            required
            >

            </textarea>
            <br></br>

            <input 
            type='submit'
            />
        </form>
    </div>
    )
}

export default ContactForm;

import '../css/main.css'
import Hero from './Hero';



const JoinUs = () => {

    const thisPage = 'join-us'


    return (
        <div>
            <Hero page={thisPage} />
            <h1 className="thematic-area-title">Join Us</h1>

            <div className="join-area">

                <p className="join-pre" >
                The Open Government Partnership is a multilateral initiative that aims to secure concrete commitments from national and sub-national governments to promote open government, empower citizens, fight corruption, and harness new technologies to strengthen governance. 
                <br></br>
                <br></br>
                Plateau State joined the OGP global netowork as parto of the 2020 cohort and following the inauguration of the State’s OGP Steering Committee, Government and Civil Society co-created the first State Action Plan for 2021 to 2023. Plateau State seeks your support  and participation in the implementation of the State Action Plan.
                Plateau State’s Commitment Areas are: Citizens Engagement; Fiscal Transparency; Gender; Open Contracting; and Peace & Security.
                    Click the button below to register to join OGP Plateau.
                    </p>
                
            <div className='join-button-div'>
                    
            <a className="join_us" href="https://docs.google.com/forms/d/1QkWO5o_ekMF-jKWoRU4kRvZjnwpupqatH4gqf10pqWQ/edit#responses" target="_blank" rel="noreferrer">Click here to join</a> 

            <a className="join_us" href="https://docs.google.com/forms/d/e/1FAIpQLSfDeAa7GEi4Ff9a-i1KeVwbX4wnbDMjDibGX6UQToX7B6x5cA/viewform?usp=sf_link" target="_blank" rel="noreferrer">Click here to support</a>

            </div>
            </div>


        </div>
    )
}

export default JoinUs;
import "../css/main.css"
import Hero from "./Hero"
import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import FiscalTransparency from './thematicAreas/FiscalTransparency';
import PeaceSecurity from './thematicAreas/PeaceSecurity';
import Gender from './thematicAreas/Gender';
import ExtractiveTransparency from './thematicAreas/ExtractiveTransparency'
import CitizensEngagement from './thematicAreas/CitizensEngagement'


const ThematicAreas = () => {

    const [pageState, setPageState] = useState('')

    const thisPage = "thematic-areas"

    const active = {
        "color": 'green'
    }
    
    // const location = useLocation();
    useEffect(() => {

        if (pageState === false){
            window.scrollTo(0, 0);
        }
      
    },[ pageState]);


    return(
        <div>
            <Hero page={thisPage} />
            <h1 className="thematic-area-title">Thematic Areas</h1>
            
            <div className="ta-main-area">
                <div className="thematic-area-nav">
                    <ul>
                        <li 
                            style={ pageState === 'fiscal' || pageState === ''? active : null}
                            onClick={()=> setPageState('fiscal')}

                        >
                            <p>Fiscal Transparency</p>
                        </li>

                        <li 
                            style={ pageState === 'citizens'? active : null}
                            onClick={()=> setPageState('citizens')}>
                            <p>citizen's Engagement</p>
                        </li>

                        <li 
                            style={ pageState === 'gender'? active : null}
                            onClick={()=> setPageState('gender')}>
                            <p>Gender</p>
                        </li>

                        <li 
                             style={ pageState === 'extractive'? active : null}
                            onClick={()=> setPageState('extractive')}>
                            <p>Extractive Transparency</p>
                        </li>

                        <li     
                             style={ pageState ==='peace'? active : null}
                            onClick={()=> setPageState('peace')}>
                            <p>Peace and Security</p>
                        </li>
                    </ul>

                </div>


        <div className="ta-display-area">
            <div>
                {
                    pageState === 'fiscal' || pageState === ''?
                        <FiscalTransparency />

                    : pageState === 'citizens' ?
                        <CitizensEngagement />

                    : pageState === 'gender'?
                        <Gender />

                    : pageState === 'extractive'?
                        <ExtractiveTransparency />
                    
                    : pageState === 'peace'?
                        <PeaceSecurity />
                    
                    : null

                }
                
            
            </div>

        </div>
    </div>
</div>
    )
}
export default ThematicAreas
import { Link } from 'react-router-dom'
import '../css/main.css'
import { useState, useEffect } from 'react'
import govLogo from '../images/government-logo.png'
import ogpLogo from '../images/ogplocal_transparent.png'
// import Offcanvas from 'react-bootstrap/Offcanvas'

// import MobMenu from './Menu'

import { Icon } from '@iconify/react';
import xLg from '@iconify/icons-bi/x-lg';
import menuAltLeft from '@iconify/icons-bx/menu-alt-left'

const Hero = ( { page }) => {
    
    const [menuState, setMenuState] = useState(false)
    const [menuClassList, setMenuClassList] = useState("nav")

    const handleOpenMenu = ()=>{
        setMenuState(true)
    }

    const handleCloseMenu = ()=>{
        setMenuState(false)
    }


    const caretStyle = {
        "color": "green"
    }

    useEffect(() => {
    if (menuState){
        setMenuClassList("nav open-nav")
    }else{
        setMenuClassList("nav")
    }
    },[menuState])

    


    return (
        <div>

            <div className="hero-div">
                <img className="hero-img" src={ogpLogo} alt=" Open government Partnership logo"></img>

                <img className="gov-logo"  src={govLogo} alt="Plateau State logo"></img>

                <Icon className='menu'  icon={menuAltLeft} onClick={handleOpenMenu} />

            </div>

            

           
        <div className='nav-container'>

        
		<nav className={menuClassList}>
        <Icon className='close-nav close' icon={xLg} onClick={handleCloseMenu} />

            <ul>
                <li>
                    {
                        page === 'home'?
                        <Link to='/' className='current-tab'>Home</Link>
                        :
                        <Link to='/'>Home</Link>

                    }
                    
                </li>
                <li>
                    {
                        page === 'thematic-areas'?
                        <Link to='/thematic-areas' className='current-tab'>Thematic Areas</Link>
                       : <Link to='/thematic-areas'>Thematic Areas</Link>

                    }
                   
                </li>
                <li>
                    <a href="https://www.opengovpartnership.org/documents/action-plan-plateau-nigeria-2021-2023/" target="_blank" rel="noreferrer">Action Plan</a>
                </li>
                

                <li>
                    <a href="https://www.opengovpartnership.org/members/plateau-nigeria/#commitments" target="_blank" rel="noreferrer" >Commitments</a>

                </li>
                <li className="dropdown">
                    <div className="dropdown-icon">
                        {
                            page === "activities"?
                            <>
                            <Link to='' className='current-tab'>Activities</Link> <span><i style={caretStyle} className="fa fa-caret-down"></i></span>
                            </>
                            : 
                            <>
                            <Link to=''>Activities</Link> <span><i className="fa fa-caret-down"></i></span>
                            </>
                        }

                       
                    </div>
                    
                    
                    <div className="dropdown-content">
                        <Link to='/events'>Events</Link>
                        <Link to='/milestones'>Milestones</Link>
                        
                    </div>
                </li>

                <li>
                    {
                       page === 'steering-committee' ?
                       <Link to='/steering-committee' className='current-tab'>Steering Committee</Link>
                       :<Link to='/steering-committee'>Steering Committee</Link>
                    }
                    
                </li>
            
                <li>
                    {
                        page === 'partners'?
                        <Link to='/partners' className='current-tab'>Partners</Link>
                        :<Link to='/partners'>Partners</Link>
                    }
                </li>

                <li>
                    {
                        page === 'join-us'?
                        <Link to='/join-us' className='current-tab'>Join Us</Link>
                        :<Link to='/join-us'>Join Us</Link>
                    }
                </li>
            </ul>

        
        </nav>
        <div className='backdrop' style={menuState ? {display:'block'} : {display:'none'}} onClick={handleCloseMenu}></div>
        </div>
    </div>
)
}

export default Hero;
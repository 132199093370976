import '../css/main.css'
import Hero from './Hero';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DavidJaafaruWuyep from '../images/steering_committee/DavidJaafaruWuyep.jpeg';
import IrimiyaJerryWerr from '../images/steering_committee/IrimiyaJerryWerr.jpeg';
import ManjiWilson from '../images/steering_committee/ManjiWilson.jpeg';
import PatriciaLar from '../images/steering_committee/PatriciaLar.jpeg';
import PeterGadShamaki from '../images/steering_committee/PeterGadShamaki.jpeg';
import PeterYDogo from '../images/steering_committee/PeterYDogo.jpeg';
import RebeccaSambo from '../images/steering_committee/RebeccaSambo.jpeg'
import ObadiahAfwanks from '../images/steering_committee/ObadiahAfwanks.jpeg'
import ChristopherDamcher from '../images/steering_committee/ChristopherDamcher.jpeg'
import RaymondDaspan from '../images/steering_committee/RaymondDaspan.jpeg'
import DavidDaser from '../images/steering_committee/DavidDaser.jpg'
import JosephLengman from '../images/steering_committee/JosephLengman.jpeg'
import JudeDakur from '../images/steering_committee/JudeDakur.jpeg'
import SylvesterWallangko from '../images/steering_committee/SylvesterWallangko.jpg'
import JacobPwakim from '../images/steering_committee/JacobPwakim.jpg'
import JessicaVonkat from '../images/steering_committee/JessicaVonkat.jpeg'



const SteeringCommittee = () => {

    const thisPage = "steering-committee"

    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);


    return (

        <div className='steering-committee-page'>
            <Hero page={thisPage} />

            <h1 className="thematic-area-title">Steering Committee</h1>
        

        
            

            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={IrimiyaJerryWerr} alt='Government Co-Chairman Plateau State OGP'></img>
                    </div>
                    
                    <p>Hon. Irmiya Jerry Werr</p>
                    <p className='smaller sc-member-position'>Commissioner for Special Duties, Intergovernmental Affairs and NGOs</p>
                    <p className='smaller'>Government Co-chair Plateau State OGP</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={ChristopherDamcher} alt='CSO Co-Chairman Plateau State OGP' ></img>
                    </div>
                    
                    <p>Mr. Christopher Damcher</p>
                    <p className='smaller sc-member-position'>Plateau State CiSHAN Coordinator</p>
                    <p className='smaller'>CSO Co-chair Plateau State OGP</p>
                
                </div>

            </div>

            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={DavidJaafaruWuyep} alt='Government Point of Contact Person Plateau State OGP '></img>
                    </div>
                    
                    <p>Engr. David Jaafaru Wuyep</p>
                    <p className='smaller sc-member-position'>Chief Technical Advisor (Development Strategy)</p>
                    <p className='smaller'>Government Point of Contact Person Plateau State OGP   </p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={PeterGadShamaki} alt='CSO Point of Contact Person Plateau State OGP' ></img>
                    </div>
                    
                    <p>Cmr. Gad Peter Shamaki</p>
                    <p className='smaller sc-member-position'>Plateau CSO Forum</p>
                    <p className='smaller'>CSO Point of Contact Person Plateau State OGP</p>
                
                </div>

            </div>


            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={PeterYDogo} alt='Government Co-chair, Open Contracting'></img>
                    </div>
                    
                    <p>Bldr. Peter Y. Dogo</p>
                    <p className='smaller sc-member-position'>Director-General,Plateau State BPP</p>
                    <p className='smaller'>Government Co-chair, Open Contracting</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={PatriciaLar} alt='CSO Co-chair, Open Contracting'></img>
                    </div>
                    
                    <p>Prof. Patricia Lar</p>
                    <p className='smaller sc-member-position'>University of Jos</p>
                    <p className='smaller'>CSO Co-chair, Open Contracting</p>
                
                </div>

            </div>


            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={SylvesterWallangko} alt='Government Co-chair Participatory budgeting'></img>
                    </div>
                    
                    <p>Hon. Sylvester Wallangko</p>
                    <p className='smaller sc-member-position'>Commissioner for Budget and Economic Planning</p>
                    <p className='smaller'>Government Co-chair Participatory budgeting</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={ObadiahAfwanks} alt='CSO Co-chair Participatory budgeting'></img>
                    </div>
                    
                    <p>Mr. Obadiah J. Afwanks</p>
                    <p className='smaller sc-member-position'>Executive Director, Wise Youth Development Foundation (WISE-DEF)</p>
                    <p className='smaller'>CSO Co-chair Participatory budgeting</p>
                
                </div>

            </div>


            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={DavidDaser} alt='Government Co-chair Citizens Engagement'></img>
                    </div>
                    
                    <p>Mr. David Daser</p>
                    <p className='smaller sc-member-position'>Director General Plateau Information and Communication Technology Development Agency(PICTDA)</p>
                    <p className='smaller'>Government Co-chair Citizens Engagement</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={ManjiWilson} alt='Co-chair Citizens Engagement'></img>
                    </div>
                    
                    <p>Mr. Manji Wilson</p>
                    <p className='smaller sc-member-position'>European Center for Electoral Support</p>
                    <p className='smaller'>Co-chair Citizens Engagement</p>
                
                </div>

            </div>

            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={JudeDakur} alt='Government Co-chair Extractive Transparency'></img>
                    </div>
                    
                    <p>Hon. Jude Dakur</p>
                    <p className='smaller sc-member-position'>Commissioner for Minerals Development</p>
                    <p className='smaller'>Government Co-chair Extractive Transparency</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={RaymondDaspan} alt='CSO Co-chair Extractive Transparency'></img>
                    </div>
                    
                    <p>Dr. Raymond Daspan</p>
                    <p className='smaller sc-member-position'>Chairman, Nigeria Mining and Geosciences Society (NMGS) Plateau Chapter</p>
                    <p className='smaller'>CSO Co-chair Extractive Transparency</p>
                
                </div>

            </div>
            

            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={RebeccaSambo} alt='Government Co-chair Gender'></img>
                    </div>
                    
                    <p>Hon. Rebecca Sambo</p>
                    <p className='smaller sc-member-position'>Honourable Commissioner for Women Affairs and Social Development</p>
                    <p className='smaller'>Government Co-chair Gender</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={JessicaVonkat} alt='CSO Co-chair Gender'></img>
                    </div>
                    
                    <p>Mrs. Jessica Vonkat</p>
                    <p className='smaller sc-member-position'>Executive Director, Country Women Association of Nigeria (COWAN) </p>
                    <p className='smaller'>CSO Co-chair Gender</p>
                
                </div>

            </div>

            <div className='sc-member-row'>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={JosephLengman} alt='Government Co-chair Peace and Security'></img>
                    </div>
                    
                    <p>Mr. Joseph Lengman</p>
                    <p className='smaller sc-member-position'>Director General, Plateau Peace Building Agency</p>
                    <p className='smaller'>Government Co-chair Peace and Security</p>
                
                </div>
                <div className='sc-member'>
                    <div className='sc-member-img'>
                    <img src={JacobPwakim} alt='CSO Co-chair Peace and Security'></img>
                    </div>
                    
                    <p>Mr. Pwakim Jacob Choji</p>
                    <p className='smaller sc-member-position'>Chairman, Plateau Peace Practitioners Network (PPPN)</p>
                    <p className='smaller'>CSO Co-chair Peace and Security</p>
                
                </div>

            </div>





            

            {/* <div className='sc-member-row'>
                <div className='sc-member'>

                </div>
                <div className='sc-member'>

                </div>

            </div> */}

        {/* <table className="steering-committee-table">
            <tr>
                <th>Role</th>
            <th>Government</th>
            <th>CSO</th>
            
            </tr>
            <tr>
            <td>Point of Contact</td>
            <td>Engr. David Jaafaru Wuyep </td>
            <td>Cmr. Gad Peter Shamaki</td>
            
            </tr>
            <tr>
            <td>Co-chair</td>
            <td>Hon. Irimiya Werr</td>
            <td>Mr. Chris Damcher </td>
            
            </tr>
            <tr>
                <td>Co-chair Open Contracting</td>
                <td>Bldr. Peter Dogo </td>
                <td>Prof. Patricia Lar</td>
            </tr>
            <tr>
                <td>Co-chair Participatory budgeting</td>
                <td>Hon. Sylvester Wallangko</td>
                <td>Mr. Obadiah J. Afwanks</td>
                
            </tr>
            <tr>
                <td>Co-chair Citizens Engagement</td>
                <td>Mr. David Daser</td>
                <td>Mr. Manji Wilson </td>
                
            </tr>
            <tr>
                <td>Co-chair Extractive Transparency</td>
                <td>Hon. Jude Dakur</td>
                <td>Dr. Raymond Daspan</td>
                
            </tr>
            <tr>
                <td>Co-chair Gender</td>
                <td>Hon. Rebecca Sambo</td>
                <td>Mrs. Jessica Vonkat</td>
            
            </tr>

            <tr>
                <td>Co-chair Peace and Security</td>
                <td>Mr. Joseph Lengman</td>
                <td>Mr. Jacob Pwakim </td>
            </tr>

      </table>
 */}

    </div>
    )
}

export default SteeringCommittee;
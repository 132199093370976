
import '../../css/main.css'
import Hero from '../Hero';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Milestones2 = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

    return(
        
        <div>
            <Hero />

            <h1 className="thematic-area-title">Milestones</h1>

<div>
   

    <div className="milestones-body">
<div className="progress-keys">
    <div className="completed-tag">Completed</div>
    <div className="ongoing-tag">Ongoing</div>
    <div className="not-started-tag">Not started</div>
</div>
        <br />

{/* commitment 3 */}
<h2 className="commitment-heading">Commitment 3: Citizen's Engagement</h2>

<table className="table">
    <tr>
      <th>Description of Milestones</th>
      <th className="progress-col">Status</th>
    </tr>
    <tr>
      <td>State Monitoring and Evaluation (M&E) Policy developed </td>
      <td className="completed-item"></td>
    </tr>
    <tr>
      <td>State Community-Driven Development (CDD) Policy developed</td>
      <td className="ongoing-item"></td>
    
    </tr>
    <tr>
        <td>Results Fair/Stakeholders’ Town Hall: Inclusive feedback meetings organized  for the executive and legislature to share their achievements and to get feedback
        </td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Click and Tell mobile application and feedback system developed and deployed across the whole state for enhanced engagements with citizens</td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Interactive Media Engagements organised periodically between high-ranking Government officials and citizens on policies, programmes and projects</td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Training Twenty Citizens per LGA on accountability and transparency processes</td>
        <td className="ongoing-item"></td>
    </tr>
    <tr>
        <td>Quarterly Analysis Report detailing citizen feedback and government responsiveness for different sitemes of the policy and budget cycles</td>
        <td className="ongoing-item"></td>
    </tr>

  </table>


{/* commitment 4 */}
  <h2 className="commitment-heading">Commitment 4: Extractive Transparency</h2>

  <table className="table">
    <tr>
        <th>Description of Milestones</th>
        <th>Status</th>
      </tr>
      <tr>
        <td>Concept Note on establishment of PSETF </td>
        <td className="completed-item"></td>
      </tr>
      <tr>
        <td>Policy statement/ Executive Order on PSETF</td>
        <td className="completed-item"></td>
      </tr>
      <tr>
          <td>PSTEF constituted through the Executive Order</td>
          <td className="not-started-item"></td>
      </tr>
      <tr>
          <td>Compendium of CDAs in the State</td>
          <td className="ongoing-item"></td>
      </tr>

      <tr>
          <td>Compendium of ESMPs in the State</td>
          <td className="ongoing-item"></td>
      </tr>
      <tr>
          <td>A record of 13% derivation from extractive sector accruing to Plateau State from 2015 to 2020</td>
          <td className="completed-item"></td>
      </tr>

  </table>
   

    </div>
    </div>

    <div className="pagination">
           <Link to='/milestones'>1</Link>
           <Link to='/milestones-2' className='active'>2</Link>
           <Link to='/milestones-3' >3</Link>
        
      </div>


        </div>
    )
}

export default Milestones2;
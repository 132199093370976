import './css/main.css'
// import ScrollToTop from './components/ScrollToTop';

import Home from './components/Home';
import ThematicAreas from './components/ThematicAreas';
import Events from './components/Events';
import SteeringCommittee from './components/SteeringCommittee';
import Partners from './components/Partners';
import JoinUs from './components/JoinUs';
import Milestones from './components/Milestones';
import Milestones2 from './components/milestones/Milestones2';
import Milestones3 from './components/milestones/Milestones3';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from 'react-router-dom'


function App() {
  return (
    <Router>
      <Routes>
          <Route path='/' element={ <Home />} />
          <Route path='/thematic-areas' element={ <ThematicAreas />} />
          <Route path='/events' element={ <Events /> } />
          <Route path='/steering-committee' element={ <SteeringCommittee /> } />
          <Route path='/partners' element={ <Partners /> } />
          <Route path='/join-us' element={ <JoinUs /> } />
          <Route path='/milestones' element={ <Milestones /> } />
          <Route path='/milestones-2' element={ <Milestones2 /> } />
          <Route path='/milestones-3' element={ <Milestones3 /> } />
        
      </Routes>

  </Router>
  );
}

export default App;

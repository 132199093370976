import { createRef, useEffect, useState } from "react";
import Hero from "./Hero";

import app from "../firebaseConfig";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(app);

const Events = () => {
  const [events, setEvents] = useState([]);
  const [years, setYears] = useState([]);
  const [yearRefs, setYearRefs] = useState({});

  const snapshotToArray = (snapshot) => {
    var returnArr = [];

    snapshot.forEach((childSnapshot) => {
      var item = childSnapshot.data();

      returnArr.push(item);
    });

    return returnArr;
  };

  useEffect(() => {
    const getAllPics = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));

      const allEventsData = snapshotToArray(querySnapshot);

      setEvents(allEventsData);
    };
    try {
      getAllPics();
    } catch (error) {
      console.log("fetch error", error);
    }
  }, []);

  useEffect(() => {
    const yearlist = events.map((_event) => _event.year);
    const uniqueYears = [...new Set(yearlist)];

    setYears(uniqueYears);

    const RefMap = {};
    uniqueYears.forEach((year) => {
      RefMap[`y${year}`] = createRef();
    });

    setYearRefs(RefMap);
  }, [events]);

  // useEffect(()=> {
  //   const uploadEvents = async () => {

  //     event2022.forEach(async (_event,index) => {

  //       _event["year"] = "2022"
  //       _event["id"] = index + 1
  //       await addDoc(collection(db, "events"), {
  //         ..._event
  //       })

  //     });
  //   }

  //   try {
  //     uploadEvents()
  //   } catch (error) {
  //     console.log(error)

  //   }
  // }, [])

  const thisPage = "activities";

  return (
    <div className="event-page">
      <Hero page={thisPage} />
      <h1 className="thematic-area-title">Events</h1>

      <div className="sidenav">
        <ul>
          {years
            .sort((a, b) => parseFloat(b) - parseFloat(a))
            .map((year, index) => {
              return (
                <li
                  key={index}
                  onClick={() => yearRefs[`y${year}`].current.scrollIntoView()}
                >
                  <p>{year}</p>
                </li>
              );
            })}
        </ul>
      </div>

      {years.map((year, index) => {
        return (
          <div key={index}>
            <h2 className="year" ref={yearRefs[`y${year}`]}>
              {year}
            </h2>
            <div className="events">
              {events
                .filter((ev) => ev.year === year)
                .sort((a, b) => a.id - b.id)
                .map((_event, index) => {
                  return (
                    <div className="event_item" key={index}>
                      <img
                        className="event_pic"
                        src={_event.url}
                        loading={"lazy"}
                        alt={_event.description}
                      ></img>
                      <p>{_event.description}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Events;

import '../css/main.css'
import Hero from './Hero';
import Milestones1 from './milestones/Milestones1';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';



const Milestones = () => {

    const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

    const thisPage = 'activities'
    
    return (
        <div>
            <Hero page={thisPage}/>
            <Milestones1 />

        <div className="pagination">
           <Link to='/milestones' className='active'>1</Link>
           <Link to='/milestones-2'>2</Link>
           <Link to='/milestones-3'>3</Link>
        
      </div>
        </div>
    )
}

export default Milestones;
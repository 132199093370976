import '../../css/main.css'

const Milestones1 = () => {

    return(
        <div>

            <h1 className="thematic-area-title">Milestones</h1>

            <div>
   

                <div className="milestones-body">
                <div className="progress-keys">
                    <div className="completed-tag">Completed</div>
                    <div className="ongoing-tag">Ongoing</div>
                    <div className="not-started-tag">Not started</div>
                </div>
                        <br />

                {/* commitment 1 */}
                <h2 className="commitment-heading">Commitment 1: Open Contracting</h2>

                <table className="table">
                    <tr>
                    <th>Description of Milestones</th>
                    <th>Status</th>
                    </tr>
                    <tr>
                    <td>Policy Guidelines reviewed and updated</td>
                    <td className="ongoing-item"></td>
                    </tr>
                    <tr>
                    <td>Policy Guidelines printed and uploaded</td>
                    <td className="not-started-item"></td>
                    
                    </tr>
                    <tr>
                        <td>Procurement Data updated and uploaded</td>
                        <td className="ongoing-item"></td>
                    </tr>
                    <tr>
                        <td>Defaulters sanctioned for corrupt practices.</td>
                        <td className="not-started-item"></td>
                    </tr>
                    <tr>
                        <td>Quarterly results-based monitoring exercise conducted to ensure funds were used for intended purposes.</td>
                        <td className="ongoing-item"></td>
                    </tr>
                    <tr>
                        <td>Conduct bi-Annual Socio-Economic Status Survey of Households</td>
                        <td className="not-started-item"></td>
                    </tr>

                </table>

                

                {/* commitment 2 */}
                <h2 className="commitment-heading">Commitment 2: Participatory Budgeting</h2>

                <table className="table">
                    <tr>
                        <th>Description of Milestones</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td>Simplified and infographic versions of the budget performance for 2021 and allocations in 2022 Budgets</td>
                        <td className="completed-item"></td>
                    </tr>
                    <tr>
                        <td>Citizens engagement/enlightenment/education on budget preparation and performance</td>
                        <td className="completed-item"></td>
                    </tr>
                    <tr>
                        <td>17 Community Development Plans</td>
                        <td className="completed-item"></td>
                    </tr>
                    <tr>
                        <td>Complete citizen’s feedback loop on budget preparation and implementation</td>
                        <td className="completed-item"></td>
                    </tr>

                </table>
                

                </div>
            </div>
    </div>

               
        
    )
}

export default Milestones1;
import { useEffect, useState } from 'react'
import currencies from '../../images/thematic_areas/nigerian-currency.jpeg'

const FiscalTransparency = () => {
    const [display, setDisplay] = useState(false)

    useEffect(()=> {
       
        setTimeout(() => {
            setDisplay(true)
        }, 300);
    }, [display ])

    return(
        <div className="ta-item" style={display? {visibility:"visible", opacity:'1'}: {visibility:"hidden"}}>
            <div className="ft-background-pic">
                    <img className="ta-picture" src={currencies} alt=""></img>
            </div>

            <div className="ta-text fiscal-transparency">
            <h2>Fiscal Transparency</h2>
            <div>
                To ensure open contracting in public procurement in order to optimise financial efficiency and minimise/prevent financial recklessness/leakages and also improve the participation of citizens in the budgeting process.
                <br></br>
                <br></br>
                <b>Commitments</b>:
                <br />
                1. Improving Transparency in Procurement Processes.
                <br />
                2. Improving Participatory Budgeting.
            </div>
        </div>
        </div>
    )
}
export default FiscalTransparency;
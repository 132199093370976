import '../css/main.css'
import Hero from './Hero';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { Slider } from 'infinite-react-carousel/lib';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Carousel from 'react-bootstrap/Carousel'
// import NextButton from '../images/chevron-right-solid.svg'
import Carousel from 'react-elastic-carousel'

// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import tearfundLogo from '../images/ogp_partners/tearfund_logo.png'
import accountabilityLabLogo from '../images/ogp_partners/accountabililtyLab.png'
import crudanLogo from '../images/ogp_partners/crudan_logo.png'
import budgitLogo from '../images/ogp_partners/budgit_logo.png'
import centerLsdLogo from '../images/ogp_partners/center_lsd_logo.png'
import dataphyteLogo from '../images/ogp_partners/dataphyteLogo.png'
import devGatewayLogo from '../images/ogp_partners/development_gateway_logo.png'
import gizLogo from '../images/ogp_partners/giz_logo.png'
import gizLogo2 from '../images/ogp_partners/giz-logo.gif'
import PPDCLogo from '../images/ogp_partners/ppdc_logo.png'



const Partners = () => {

    const thisPage = 'partners'

    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);


    return (
        <div>
            <Hero page={thisPage}/>

            <h1 className="thematic-area-title">Partners</h1>

            <div className="partners">
              <div className='to-flex'>
                <div className="partner tearfund-logo">
                    <a href="https://www.tearfund.org/" target="_blank" rel="noreferrer"><img src={tearfundLogo} alt="Tearfund Logo"></img></a>
                </div>
                <div className="partner crudan-logo">
                    <a href="https://crudan.org" target="_blank" rel="noreferrer" ><img src={crudanLogo} alt="Crudan Logo"></img></a>
                </div>
              </div>
                <div className="partner giz-logo">
                    <a href="https://www.giz.de/en/html/index.html" target="_blank" rel="noreferrer"><img src={gizLogo} alt="GIZ Logo"></img>
                  
                    </a>
                    <a href="https://www.giz.de/en/html/index.html" target="_blank" rel="noreferrer"><img className='giz2' src={gizLogo2} alt="GIZ Logo"></img>
                  
                    </a>
                
                </div>
                <div className="partner center-lsd-logo">
                    <a href="https://centrelsd.org/" target="_blank" rel="noreferrer"><img src={centerLsdLogo} alt="Center LSD Logo"></img></a>
                </div>
                <div className="partner budgit-logo">
                    <a href="https://yourbudgit.com/" target="_blank" rel="noreferrer"><img src={budgitLogo} alt="Budgit Logo"></img></a>
                </div>
                <div className="partner dev-gateway-logo">
                    <a href="https://developmentgateway.org/" target="_blank" rel="noreferrer"><img src={devGatewayLogo} alt="Development gateway Logo"></img></a>
                </div>
                <div className="partner dataphyte-logo">
                    <a href="https://www.dataphyte.com/" target="_blank" rel="noreferrer"><img src={dataphyteLogo} alt="Dataphyte Logo"></img></a>
                </div>
                <div className="partner acc-lab-logo">
                    <a href="https://accountabilitylab.org/about-us/country-office-nigeria/ " target="_blank" rel="noreferrer"><img src={accountabilityLabLogo} alt="Accountability Lab Logo"></img></a>
                </div>
                <div className="partner ppdc-logo">
                    <a href="https://www.procurementmonitor.org/ " target="_blank" rel="noreferrer"><img src={PPDCLogo} alt="Development gateway Logo"></img></a>
                </div>


                <h2 className='other-partners'>other partners</h2>

        
<Carousel itemsToShow={1} className='carousel' >
<div className='item'>
             <div>
                <div>Plateau State CSOs Forum</div>
              </div>
              <div>
                <div>Reboot Africa</div>
              </div>
              <div>
                <div>Country Women Association of Nigeria (COWAN)</div>
              </div>
              <div>
                <div>Community Initiative for Sustainable Development (COIN)</div>
              </div>
              <div>
                <div>Plateau Peace Practitioners Network</div>
              </div>
              <div>
                <div>University of Jos</div>
              </div>
              <div>
              <div>Int'l Center for Peace, Charities and Human Development (INTERCEP)</div>
            </div>
            <div>
              <div>Tattaunawa RoundTable Initiative Center</div>
            </div>
            <div>
              <div>Organized Center for Empowerment and Advocacy in Nigeria (OCEAN)</div>
            </div>

      </div>

      <div className='item'>
        
        <div>
          <div>Esther Mallo Women Initiative</div>
        </div>
        <div>
          <div>Fahariya Adolescent Development Network (FAANET)</div>
        </div>
        <div>
          <div>European Centre for Electoral Support</div>
        </div>
        <div>
          <div>Wise-Youth Development Foundation</div>
        </div>
        <div>
          <div>Peace Practitioners Network</div>
        </div>
        <div>
          <div>League for Human Rights</div>
        </div>
        <div>
          <div>Youth Adolescent Reflection and Action Center (YARAC)</div>
        </div>
        <div>
          <div>BEGE Foundation</div>
        </div>
        <div>
          <div>Centre for the Advocacy of Justice and Rights / Budget & Transparency Network</div>
        </div>
        <div>
          <div>Women and Girlchild Rescue and Development Initiative</div>
        </div>
  </div>

  <div className='item'>
        <div>
    <div>NEWSAN</div>
  </div>
  <div>
    <div>Youth Initiative Against Violence and Human Rights Abuse(YIAVHA)</div>
  </div>
  <div>
    <div>Journalism Communication and Media Centre</div>
  </div>
  <div>
    <div>APURIMAC</div>
  </div>
  <div>
    <div>Africare Peace Initiative</div>
  </div>
  <div>
    <div>CHCEEWY</div>
  </div>
  <div>
    <div>Civil Liberties Organization</div>
  </div>
  <div>
    <div>Justice Development and Peace Commission (JDPC)</div>
  </div>
  <div>
    <div>Saphirra Global Center for Social Development</div>
  </div>
  <div>
    <div>Plateau State Polytechnic</div>
  </div>
  <div>
    <div>Women’s Rights Advancement and Protection Alternative (WRAPA)</div>
  </div>
        
      </div>
  


</Carousel>


            </div>

  
        </div>
    )
}

export default Partners;
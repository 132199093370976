import { useEffect, useState } from 'react'
import people from '../../images/thematic_areas/citizensEngagement2.jpg'



const CitizensEngagement = () => {
    const [display, setDisplay] = useState(false)

    useEffect(()=> {
       
        setTimeout(() => {
            setDisplay(true)
        }, 300);
    }, [display ])

    return(
        <div className="ta-item" style={display? {visibility:"visible", opacity:'1'}: {visibility:"hidden"}}>
            <div className="ft-background-pic">
                    <img className="ta-picture" src={people} alt=""></img>
            </div>

            <div className="ta-text citizens-engagement">
            <h2>Citizens Engagement</h2>
                <div>
                    To create the space for citizens, citizen organisations and the media to thrive, express themselves and participate in the different stages of the policy making process without fear or intimidation.
                    <br></br>
                    <br></br>

                    <b>Commitment</b>:
                    <br></br>
                     Establishing Citizens Dialogue Mechanisms for improved Accountability and Transparency.
                </div>
        </div>
        </div>
    )
}
export default CitizensEngagement;
import { useEffect, useState } from 'react';
import peace from '../../images/thematic_areas/peaceimg.png'

const PeaceSecurity = () => {
    const [display, setDisplay] = useState(false)

    useEffect(()=> {
       
        setTimeout(() => {
            setDisplay(true)
        }, 300);
    }, [display ])

    return(
        <div className="ta-item" style={display? {visibility:"visible", opacity:'1'}: {visibility:"hidden"}}>
            <div className="ft-background-pic">
                    <img className="ta-picture" src={peace} alt=""></img>
            </div>

            <div className="ta-text peace-and-security">
            <h2>Peace And Security</h2>

                <div>
                    To create and maintain a peaceful environment for development.
                    <br></br>
                    <br></br>
                <b>Commitment</b>: 
                <br></br>
                Establishing an Independent Peace and Security Trust Fund.
                </div>
        </div>
        </div>
    )
}
export default PeaceSecurity;
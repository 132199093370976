import { useEffect, useState } from 'react';

import miningSite from '../../images/thematic_areas/miningsite.jpg'

const ExtractiveTransparency = () => {
    const [display, setDisplay] = useState(false)

    useEffect(()=> {
       
        setTimeout(() => {
            setDisplay(true)
        }, 300);
    }, [display ])

    return(
        <div className="ta-item" style={display? {visibility:"visible", opacity:'1'}: {visibility:"hidden"}}>
            <div className="ta-pic-container">
                <img className="ta-picture" src={miningSite} alt=""></img>
            </div>

            <div className="ta-text extractive-transparency">
            <h2>Extractive Transparency</h2>
                <div>
                    Increasing Transparency and Accountability in Extractive Sector through the establishment of the Plateau State Extractives Transparency Forum (PSETF).
                    
                    <br></br>
                    <br></br>
                    <div>
                        <b>Commitment</b>: 
                        <br></br>
                        Increasing Transparency and Accountability in Extractive Sector.

                    </div>
                    
                </div>
        </div>
        </div>
    )
}
export default ExtractiveTransparency;
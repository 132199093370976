import { useEffect, useState } from 'react'
import genderScale from '../../images/thematic_areas/genderequality.png'

const Gender = () => {
    const [display, setDisplay] = useState(false)

    useEffect(()=> {
       
        setTimeout(() => {
            setDisplay(true)
        }, 300);
    }, [display ])

    return(
        <div className="ta-item" style={display? {visibility:"visible", opacity:'1'}: {visibility:"hidden"}}>
            <div className="ft-background-pic">
                    <img className="ta-picture" src={genderScale} alt=""></img>
            </div>

            <div className="ta-text gender">
            <h2>Gender </h2>
                <div>
                    To enhance participation of women in governance and decision-making processes.
                    <br></br>
                    <br></br>
                    <b>Commitment</b>: 
                    <br></br> 
                    Improving Gender Equality through the establishment of a Gender and Equal Opportunities Commission.
                </div>
        </div>
        </div>
    )
}
export default Gender;
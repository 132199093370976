import '../css/main.css'
import Hero from './Hero';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContactForm from './ContactForm';


const Home = () => {

    const thisPage = 'home'

    const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
    return (
    	<div className='home'>
        <Hero page={thisPage}/>

	<section className="ogp-section">

        <div className="descriptions">
            <div className="background-1"></div>
            <div className="item-1">
                <h1 className="ogp-section-title">
                    Open Government Partnership
                </h1>
                <div className="ogp-item">
                    <p className="para">
                        The OGP aims to secure concrete commitments from national and sub-national governments that drive open government reform and innovation in an effort to push countries further in the areas of transparency, accountability, citizen engagement and the use of technology to achieve them.
                    </p>
                </div>
                
            </div>

            <div className="background-2"></div>
            <div className="item-2">
                <h1 className="ogp-section-title">
                    Plateau State Government
                   
                </h1>
    
                <div className="gov-item">
                    <p className="para2">
                        Plateau State joined OGP as part of the 2020 cohort. We are currently implementing six commitments from our 2021-2023 action plan.
    
                        This is the state's first action plan and it features commitments related to fiscal transparency, gender equity, extractive transparency, public participation, peace and security.
                    
                    </p>
                </div>
                    
            </div>
        </div>
    </section >

	<p className="rider">
        ...Improving governance, renewing trust.
    </p>



    <hr className="divider"></hr>
    <h2 className="contact-head" >
        Contact Us
    </h2>

    <div className="contact-us">
        
        <div className=" contact contact-1">
            <h3> David Jaafaru Wuyep</h3>
            <p><a className="contact-phone" href="tel:+2349039886397">+2349039886397 (Landline)</a></p>

            <p><a className="contact-mail" href="mailto:ogp@plateaustate.gov.ng">ogp@plateaustate.gov.ng</a></p>
            
        </div>

        <div className=" contact contact-2">
            <h3> Gad Peter Shamaki</h3>
            <p > <a className="contact-phone" href="tel:+2348034528467">+2348034528467</a> </p>
           
            <p ><a  className="contact-mail" href="mailto:gadpeter345@gmail.com">gadpeter345@gmail.com</a></p>
            
        </div>
    </div>
    
    <div>
        <ContactForm />
    </div>


  

</div>


    
                
        
 );
}

export default Home;